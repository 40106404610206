import React, { useEffect, useState } from 'react';
import BasicStructure from '../../components/BasicStructure';
import styles from './HomeAluno.module.css';
import { GoHome } from "react-icons/go";
import { GoHomeFill } from "react-icons/go";
import { TfiLayoutGrid2Alt } from "react-icons/tfi";
import { TfiLayoutGrid2 } from "react-icons/tfi";
import { FaRegBell } from "react-icons/fa";
import { FaBell } from "react-icons/fa6";
import { MdOutlineModeComment } from "react-icons/md";
import { MdModeComment } from "react-icons/md";

import img from '../../assets/Logos/Logotipo escola municipal minimalista azul e laranja.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getTenants } from '../../actions/UserActions';
import { useSelector } from 'react-redux';
import { RiEdit2Line } from "react-icons/ri";
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import { TfiAgenda } from "react-icons/tfi";
import { PiAppleLogoBold } from "react-icons/pi";
import { RiMegaphoneLine } from "react-icons/ri";
import { PiExam } from "react-icons/pi";
import apiAxios from '../../api/apiAxios';
import useTenantId from '../tenantId';

const HomeAluno = () => {
    const [loading, setLoading] = useState(true);

    const [tenants, setTenants] = useState([]);
    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
    const tenantId = useTenantId();

    const getItemsPageDeitado = () => [
        {
            icon: <GoHome />,
            iconSelected: <GoHomeFill />,
            link: "/",
            isSelected: true
        },
        {
            icon: <FaRegBell />,
            iconSelected: <FaBell />,
            link: "/notificacoes",
            isSelected: false
        },
        {
            icon: <MdOutlineModeComment />,
            iconSelected: <MdModeComment />,
            link: "/mensagens",
            isSelected: false
        },
        {
            icon: <TfiLayoutGrid2 />,
            iconSelected: <TfiLayoutGrid2Alt />,
            link: "/menu",
            isSelected: false
        }
    ];

    const getItemsPageEmPe = () => [
        {
            icon: <GoHome />,
            iconSelected: <GoHomeFill />,
            link: "/",
            isSelected: true
        },
        {
            icon: <FaRegBell />,
            iconSelected: <FaBell />,
            link: "/notificacoes",
            isSelected: false
        },
        {
            icon: <MdOutlineModeComment />,
            iconSelected: <MdModeComment />,
            link: "/mensagens",
            isSelected: false
        },
        {
            icon: <TfiLayoutGrid2 />,
            iconSelected: <TfiLayoutGrid2Alt />,
            link: "/menu",
            isSelected: false
        }
    ];

    const itemsPageDeitado = getItemsPageDeitado();
    const itemsPageEmPe = getItemsPageEmPe();

    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const image = new Image();
        image.onload = () => {
            setImagesLoaded(true);
        };
        image.src = img;
    }, []);

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const response = await getTenants();
                const foundTenant = response.find(tenant => tenant._id === tokenPayload.tenantId);
                if (foundTenant) {
                    setTenants([foundTenant]); // Define tenants como um array com o tenant encontrado
                } else {
                    setTenants([]); // Define tenants como uma array vazia se nenhum tenant for encontrado
                }
                setLoading(false); // Dados carregados, atualiza o estado de carregamento
            } catch (error) {
                console.error('Error fetching tenants:', error);
                setLoading(false); // Em caso de erro, atualiza o estado de carregamento
            }
        };
        fetchTenants();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    // Substitua a lista de imagens com as imagens dos informes
    const [informes, setInformes] = useState([]);

    useEffect(() => {
        const fetchInformes = async () => {
            try {
                const response = await apiAxios.get('/api/inf/' + tenantId);
                setInformes(response.data);
            } catch (error) {
                console.error('Erro ao buscar informes:', error);
            }
        };

        fetchInformes();
    }, []);

    console.log(informes)

    const renderImages = () => (
        informes.length > 1
            ? informes.map((informe) => (
                <div className={styles.sliderContainer} key={informe._id}>
                    <img src={informe.imagem} alt={informe.titulo} />
                </div>
            ))
            : <div className={styles.sliderContainer}>
                <img src={informes[0]?.imagem} alt={informes[0]?.titulo} />
            </div>
    );


    const Functions = [
        {
            id: 1,
            title: "Atividades",
            icon: <RiEdit2Line />,
            link: "/atividades"
        },
        {
            id: 2,
            title: "Agenda",
            icon: <TfiAgenda />,
            link: "/agenda"
        },
        {
            id: 3,
            title: "Cardápio",
            icon: <PiAppleLogoBold />,
            link: "/cardapio"
        },
        {
            id: 4,
            title: "Informes",
            icon: <RiMegaphoneLine />,
            link: "/informes"
        },
        {
            id: 5,
            title: "Boletins",
            icon: <PiExam />,
            link: "/boletis"
        }
    ]

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const response = await getTenants();
                const foundTenant = response.find(tenant => tenant._id === tokenPayload.tenantId);
                if (foundTenant) {
                    setTenants([foundTenant]); // Define tenants como um array com o tenant encontrado
                } else {
                    setTenants([]); // Define tenants como uma array vazia se nenhum tenant for encontrado
                }
            } catch (error) {
                console.error('Error fetching tenants:', error);
            }
        };
        fetchTenants();
    }, []);

    const ContentPage = (
        <>
            <div className={styles.topHome}>
                {tenants.map((tenant) => (
                    <img key={tenant._id} src={tenant.imageUrl} alt='logo' />
                ))}
                <p>Olá, Bem vindo {tokenPayload.firstName}! 👋 </p>
            </div>
            <div className={styles.grid}>
                {imagesLoaded && informes.length > 0 && (
                    informes.length > 1 ? (
                        <Slider {...settings} className={styles.sliderHome}>
                            {renderImages()}
                        </Slider>
                    ) : (
                        <div className={styles.sliderContainer}>
                            <img src={informes[0].imagem} alt={informes[0].titulo} />
                        </div>
                    )
                )}

            </div>
            <div className={styles.listFunctions}>
                {Functions.map((item) => (
                    <Link key={item.id} to={item.link}>
                        <div className={styles.icon}>{item.icon}</div>
                        {item.title}
                    </Link>
                ))}
            </div>
        </>
    )

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className={styles.screen}>
                    <BasicStructure itemsPageDeitado={itemsPageDeitado} itemsPageEmPe={itemsPageEmPe} ContentPage={ContentPage} />
                </div>
            )}
        </>
    );
}

export default HomeAluno;