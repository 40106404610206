import React, { useState, useEffect } from 'react';
import styles from './CardapioComponent.module.css';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Loading from '../../../pages/Loading';
import { useSelector } from 'react-redux';
import apiAxios from '../../../api/apiAxios';

import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

const CardapioComponent = () => {
    const [loading, setLoading] = useState(true);
    const [cardapios, setCardapios] = useState([]);
    const [error, setError] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const daysOfWeek = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo'];

    const userInfo = useSelector(state => state.user.userInfo);
    const tenantId = JSON.parse(atob(userInfo.token.split('.')[1])).tenantId;

    useEffect(() => {
        const fetchCardapios = async () => {
            try {
                const response = await apiAxios.get(`/api/card/${tenantId}`);
                setCardapios(response.data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
        fetchCardapios();
    }, [tenantId]);

    const handleMonthChange = (monthIndex) => {
        setSelectedMonth(monthIndex);
    };

    const filteredCardapios = cardapios.filter((cardapio) => {
        const cardapioDate = new Date(cardapio.dateFormatted || cardapio.date);
        return cardapioDate.getMonth() === selectedMonth;
    });

    const renderObservacao = (observacao) => {
        try {
            const contentState = convertFromRaw(JSON.parse(observacao));
            const editorState = EditorState.createWithContent(contentState);
            return { __html: stateToHTML(editorState.getCurrentContent()) };
        } catch (error) {
            console.error("Erro ao renderizar observação:", error);
            return { __html: "Observação indisponível" };
        }
    };

    return (
        loading ? (
            <Loading />
        ) : (
            <div className={styles.screen}>
                <div className={styles.TopArea}>
                    <div className={styles.right}>
                        <Link to="/" className={styles.backLink}>
                            <IoIosArrowBack />
                        </Link>
                    </div>
                    <div className={styles.center}>Cardápio</div>
                    <div className={styles.left}></div>
                </div>

                <div className={styles.monthsContainer}>
                    {months.map((month, index) => (
                        <button
                            key={index}
                            className={`${styles.monthButton} ${selectedMonth === index ? styles.selected : ''}`}
                            onClick={() => handleMonthChange(index)}
                        >
                            {month}
                        </button>
                    ))}
                </div>

                <div className={styles.listCardapio}>
                    {error ? (
                        <p>Ocorreu um erro: {error}</p>
                    ) : filteredCardapios.length === 0 ? (
                        <p>Nenhum cardápio encontrado para este mês.</p>
                    ) : (
                        filteredCardapios.map((cardapio) => (
                            <div className={styles.table_all} key={cardapio._id}>
                                <h2>
                                    {cardapio.Titulo} (
                                    {new Date(cardapio.dateFormatted || cardapio.date).toLocaleDateString()} -
                                    {new Date(cardapio.dateFormattedEnd || cardapio.date).toLocaleDateString()})
                                </h2>
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th>Refeição</th>
                                            {daysOfWeek.map((day, index) => (
                                                <th key={index}>{day}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cardapio.alimentacao.map((alimentacao, index) => (
                                            <tr key={index}>
                                                <td className={styles.refeicao}>{alimentacao.refeicao}</td>
                                                {daysOfWeek.map((day, dayIndex) => {
                                                    const conteudo = alimentacao.conteudo.find(c => c.diaDaSemana === day);
                                                    return (
                                                        <td key={dayIndex}>
                                                            {conteudo && conteudo.itemCardapioDetails && conteudo.itemCardapioDetails.length > 0 ? (
                                                                <ul className={styles.itemList}>
                                                                    {conteudo.itemCardapioDetails.map((item, itemIndex) => (
                                                                        <li key={itemIndex}>
                                                                            {item.titulo || "Nome Indisponível"} ({item.descricao || "Descrição indisponível"})
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            ) : 'N/A'}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {cardapio.observacao && (
                                    <div
                                        className={styles.observacao}
                                        dangerouslySetInnerHTML={renderObservacao(cardapio.observacao)}
                                    />
                                )}
                            </div>
                        ))
                    )}
                </div>
            </div>
        )
    );
};

export default CardapioComponent;
