import React, { useState, useEffect } from 'react';
import Header from './Header';
import styles from './BasicStructure.module.css';
import { Link } from 'react-router-dom';

const BasicStructure = ({ itemsPageDeitado, itemsPageEmPe, ContentPage }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.screen}>
            <Header />
            <div className={styles.AllScreen}>
                <div className={styles.content}>
                    <div className={styles.element}>
                        {ContentPage}
                    </div>
                </div>
                <div className={styles.menu}>
                    <div className={styles.menuitems}>
                        {isMobile
                            ? itemsPageDeitado.map((item, index) => (
                                <div className={item.isSelected ? styles.selected : ''} key={index}>
                                    <Link to={item.link}>
                                        {item.isSelected ? item.iconSelected : item.icon}
                                    </Link>
                                </div>
                            ))
                            : itemsPageEmPe.map((item, index) => (
                                <div className={item.isSelected ? styles.selected : ''} key={index}>
                                    <Link to={item.link}>
                                        {item.isSelected ? item.iconSelected : item.icon}
                                    </Link>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicStructure;
