import apiAxios from "../api/apiAxios";

export const sendResponse = async (atividadeId, respostas) => {
    try {
        const res = await apiAxios.post(`/api/atv/res/respostas/${atividadeId}`, respostas);
        return res.data;
    } catch (err) {
        console.error("Erro ao enviar as respostas:", err);
        throw err;
    }
};

export const getResponsesByTenant = async (tenantId) => {
    try {
        const res = await apiAxios.get(`/api/atv/res/res/tenant/${tenantId}`);
        return res.data;
    } catch (err) {
        console.error("Erro ao obter as respostas:", err);
        throw err;
    }
};
