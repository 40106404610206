import apiAxios from "../api/apiAxios";

export const getAllAtividades = async (tenantId) => {
    try {
        const res = await apiAxios.get(`api/atv/${tenantId}`);
        return res.data;
    } catch (err) {
        console.error("Erro ao buscar todos os usuários:", err);
        throw err;
    }
};

export const getAtividade = async (AtvId) => {
    try {
        const res = await apiAxios.get(`api/atv/find/${AtvId}`);
        return res.data;
    } catch (err) {
        console.error("Erro ao buscar todos os usuários:", err);
        throw err;
    }
};