import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiAxios from '../../api/apiAxios';
import styles from './Recuperacao.module.css';
import img from '../../assets/Design sem nome (1).png';

const Recuperacao = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handlePasswordReset = async () => {
    if (password !== confirmPassword) {
      setError('As senhas não coincidem.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await apiAxios.post(`/api/user/reset-password/${token}`, { password });

      if (response.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } else {
        setError(response.data.message || 'Erro ao redefinir a senha.');
      }
    } catch (error) {
      setError('Erro ao redefinir a senha. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = password.trim() !== '' && confirmPassword.trim() !== '';

  return (
    <div className={styles.container}>
      {success ? (
          <div style={{width:"60%",height:"60%"}} className={styles.form}>
            <div style={{width:"100%",height:"100%"}}>
                <h2>Sucesso!🎊</h2>
                <p><strong>Sua senha foi redefinida com sucesso</strong>. Redirecionando para a página de login...</p>
            </div>
        </div>
      ) : (
        <div className={styles.form}>
          <div>
            <h2>Definir nova senha</h2>
            <p>Informe a sua <strong>nova senha</strong>. Agora você irá usá-la para ter acesso ao <strong> Educação Alternativa 360</strong>.</p>
            <div className={styles.send}>
              <input
                type="password"
                placeholder="Nova Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={styles.input}
              />
              <input
                type="password"
                placeholder="Confirme a Nova Senha"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={styles.input}
              />
              {error && <p className={styles.error}>{error}</p>}
              <button
                onClick={handlePasswordReset}
                disabled={loading || !isFormValid}
                className={isFormValid ? styles.buttonEnabled : styles.buttonDisabled}
              >
                {loading ? 'Redefinindo...' : 'Redefinir Senha'}
              </button>
            </div>
          </div>
          <div className={styles.img}>
            <img src={img} alt='img' />
          </div>
        </div>
      )}
    </div>
  );
};

export default Recuperacao;
