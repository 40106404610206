import React, { useEffect, useState } from 'react'
import Loading from '../../Loading';
import { getTenants } from '../../../actions/UserActions';
import { useSelector } from 'react-redux';
import styles from './MenuAluno.module.css'
import { GoHome, GoHomeFill } from 'react-icons/go';
import { FaBell, FaRegBell } from 'react-icons/fa6';
import { TfiAgenda, TfiLayoutGrid2, TfiLayoutGrid2Alt } from 'react-icons/tfi';
import { MdModeComment, MdOutlineModeComment } from 'react-icons/md';
import { RiEdit2Line, RiMegaphoneLine } from 'react-icons/ri';
import { PiAppleLogoBold, PiExam } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import BasicStructure from '../../../components/BasicStructure';
import img from '../../../assets/Logos/Logotipo escola municipal minimalista azul e laranja.png';
import img2 from '../../../assets/Logos/Cópia de Logotipo escola municipal minimalista azul e laranja.png'
const MenuAluno = () => {
    const [loading, setLoading] = useState(true);

    const [tenants, setTenants] = useState([]);
    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));

    const getItemsPageDeitado = () => [
        {
            icon: <GoHome />,
            iconSelected: <GoHomeFill />,
            link: "/",
            isSelected: false
        },
        {
            icon: <FaRegBell />,
            iconSelected: <FaBell />,
            link: "/notificacoes",
            isSelected: false
        },
        {
            icon: <MdOutlineModeComment />,
            iconSelected: <MdModeComment />,
            link: "/mensagens",
            isSelected: false
        },
        {
            icon: <TfiLayoutGrid2 />,
            iconSelected: <TfiLayoutGrid2Alt />,
            link: "/menu",
            isSelected: true
        }
    ];

    const getItemsPageEmPe = () => [
        {
            icon: <GoHome />,
            iconSelected: <GoHomeFill />,
            link: "/",
            isSelected: false
        },
        {
            icon: <FaRegBell />,
            iconSelected: <FaBell />,
            link: "/notificacoes",
            isSelected: false
        },
        {
            icon: <MdOutlineModeComment />,
            iconSelected: <MdModeComment />,
            link: "/mensagens",
            isSelected: false
        },
        {
            icon: <TfiLayoutGrid2 />,
            iconSelected: <TfiLayoutGrid2Alt />,
            link: "/menu",
            isSelected: true
        }
    ];

    const itemsPageDeitado = getItemsPageDeitado();
    const itemsPageEmPe = getItemsPageEmPe();

    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const image = new Image();
        image.onload = () => {
            setImagesLoaded(true);
        };
        image.src = img;
    }, []);

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const response = await getTenants();
                const foundTenant = response.find(tenant => tenant._id === tokenPayload.tenantId);
                if (foundTenant) {
                    setTenants([foundTenant]); // Define tenants como um array com o tenant encontrado
                } else {
                    setTenants([]); // Define tenants como uma array vazia se nenhum tenant for encontrado
                }
                setLoading(false); // Dados carregados, atualiza o estado de carregamento
            } catch (error) {
                console.error('Error fetching tenants:', error);
                setLoading(false); // Em caso de erro, atualiza o estado de carregamento
            }
        };
        fetchTenants();
    }, []);

    const Functions = [
        {
            id: 1,
            title: "Atividades",
            icon: <RiEdit2Line />,
            link: "/atividades"
        },
        {
            id: 2,
            title: "Agenda",
            icon: <TfiAgenda />,
            link: "/agenda"
        },
        {
            id: 3,
            title: "Cardápio",
            icon: <PiAppleLogoBold />,
            link: "/cardapio"
        },
        {
            id: 4,
            title: "Informes",
            icon: <RiMegaphoneLine />,
            link: "/informes"
        },
        {
            id: 5,
            title: "Boletins",
            icon: <PiExam />,
            link: "/boletis"
        }
    ]

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const response = await getTenants();
                const foundTenant = response.find(tenant => tenant._id === tokenPayload.tenantId);
                if (foundTenant) {
                    setTenants([foundTenant]); // Define tenants como um array com o tenant encontrado
                } else {
                    setTenants([]); // Define tenants como uma array vazia se nenhum tenant for encontrado
                }
            } catch (error) {
                console.error('Error fetching tenants:', error);
            }
        };
        fetchTenants();
    }, []);

    const ContentPage = (
        <>
            <div className={styles.backgroundTop}>
                <img src={img2} alt='img'/>
            </div>
            <div className={styles.listFunctions}>
                {Functions.map((item) => (
                    <Link key={item.id} to={item.link}>
                        <div className={styles.icon}>{item.icon}</div>
                        {item.title}
                    </Link>
                ))}
            </div>
        </>
    )

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className={styles.screen}>
                    <BasicStructure itemsPageDeitado={itemsPageDeitado} itemsPageEmPe={itemsPageEmPe} ContentPage={ContentPage} />
                </div>
            )}
        </>
    );
}

export default MenuAluno