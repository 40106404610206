import React, { useEffect, useState } from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAtividade } from '../../actions/AtividadesActions';
import { sendResponse } from '../../actions/ResponseActions';
import styles from './ResponseAtividadeAluno.module.css';
import { useSelector } from 'react-redux';
import Loading from '../Loading';
import { getResponsesByTenant } from '../../actions/ResponseActions';
import { IoIosArrowDropright, IoIosArrowDropleft, IoIosClose } from "react-icons/io";
import { BsDoorOpen } from "react-icons/bs";

const ResponseAtividadeAluno = () => {
    const { AtvId } = useParams();
    const [atividade, setAtividade] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [responseResult, setResponseResult] = useState(null);
    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
    const userId = tokenPayload._id;
    const tenantId = tokenPayload.tenantId;
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const navigate = useNavigate();
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    useEffect(() => {
        const fetchAtividade = async () => {
            try {
                const data = await getAtividade(AtvId);
                setAtividade(data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        if (AtvId) {
            fetchAtividade();

            getResponsesByTenant(tenantId).then(responses => {
                const userResponse = responses.find(response => response.userid === userId && response.atividadeId === AtvId);
                if (userResponse) {
                    navigate('/notfound');
                }
            }).catch(err => {
                console.error('Erro ao obter as respostas:', err);
            });
        } else {
            setError(new Error("AtvId is not defined"));
            setLoading(false);
        }
    }, [AtvId, userId, tenantId, navigate]);

    const handleAnswerChange = (questaoIndex, opcaoIndex) => {
        setSelectedAnswers(prevState => ({
            ...prevState,
            [questaoIndex]: opcaoIndex
        }));
    };

    const handleSubmit = async () => {
        if (!atividade) {
            setError(new Error("Atividade não carregada"));
            return;
        }

        const respostas = {
            userid: userId,
            respostas: Object.keys(selectedAnswers).map(questaoIndex => {
                const opcaoIndex = selectedAnswers[questaoIndex];
                if (opcaoIndex !== undefined && atividade.questoes[questaoIndex]) {
                    return {
                        respostaqmarcou: atividade.questoes[questaoIndex].respostas[opcaoIndex].item
                    };
                } else {
                    return {
                        respostaqmarcou: null
                    };
                }
            })
        };

        if (respostas.respostas.some(resposta => resposta.respostaqmarcou === null)) {
            setError(new Error("Por favor, responda todas as questões."));
            return;
        }

        try {
            const result = await sendResponse(AtvId, respostas);
            setResponseResult(result);
            navigate('/atividades');
        } catch (err) {
            setError(err);
        }
    };

    const handleGoBack = () => {
        setCurrentQuestionIndex(prevIndex => Math.max(prevIndex - 1, 0));
    };

    const handleNextQuestion = () => {
        if (currentQuestionIndex < atividade.questoes.length - 1) {
            setCurrentQuestionIndex(prevIndex => prevIndex + 1);
        }
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Erro ao carregar atividade: {error.message}</div>;
    }

    return (
        <div className={styles.atividadeContainer}>
            <div className={styles.topArea}>
                <Link onClick={togglePopup}>
                    <IoCloseSharp />
                </Link>
            </div>
            <div className={styles.atividadeContent}>
                <h1>{atividade.titulo}</h1>
                <div>
                    <div key={currentQuestionIndex} className={styles.questao}>
                        <h3>{`Questão ${currentQuestionIndex + 1}`}</h3>
                        <p>{atividade.questoes[currentQuestionIndex].questao}</p>
                        <div className={styles.opcoes}>
                            {atividade.questoes[currentQuestionIndex].respostas.map((opcao, opcaoIndex) => (
                                <div key={opcaoIndex} className={styles.opcao}>
                                    <label>{opcao.item}) </label>
                                    <input
                                        type="radio"
                                        name={`questao_${currentQuestionIndex}`}
                                        value={opcao.item}
                                        checked={selectedAnswers[currentQuestionIndex] === opcaoIndex}
                                        onChange={() => handleAnswerChange(currentQuestionIndex, opcaoIndex)}
                                    />
                                    <label>{opcao.resposta}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.actionsAtv}>
                    <div>
                        <button onClick={handleGoBack} disabled={currentQuestionIndex === 0}
                            className={currentQuestionIndex === 0 ? styles.disabled : ''}><IoIosArrowDropleft /> Voltar</button>
                        <button onClick={handleNextQuestion} disabled={currentQuestionIndex === atividade.questoes.length - 1}
                            className={currentQuestionIndex === atividade.questoes.length - 1 ? styles.disabled : ''}>Próxima <IoIosArrowDropright /></button>
                    </div>
                    <div>
                        <button className={`${styles.send} ${currentQuestionIndex !== atividade.questoes.length - 1 ? styles.disabled : ''}`} onClick={handleSubmit} disabled={currentQuestionIndex !== atividade.questoes.length - 1}>Enviar Respostas</button>
                    </div>
                </div>
                {responseResult && (
                    <div className={styles.result}>
                        <h2>Resultado:</h2>
                        <p>{JSON.stringify(responseResult)}</p>
                    </div>
                )}
            </div>
            {isPopupOpen && (
                <div className={styles.ConfirmStartAtv}>
                    <div className={styles.popUpConfirmAtv}>
                        <div className={styles.contentClose}>
                            <IoIosClose style={{ cursor: "pointer" }} onClick={togglePopup} />
                        </div>
                        <BsDoorOpen className={styles.alert} />
                        <hr />
                        <div className={styles.contentConfirm}>
                            <div className={styles.title}>Tem certeza que deseja sair da atividade?</div>
                            <div className={styles.action}>
                                <Link style={{background:"rgb(252, 210, 25)"}} to="/atividades" onClick={togglePopup}>Tentar mais tarde</Link>
                                <Link onClick={togglePopup}>Cancelar</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResponseAtividadeAluno;
