import React, { useEffect, useRef, useState } from 'react'
import styles from "./Header.module.css"
import { clearUserInfo, logout } from '../actions/UserActions';
import { useDispatch, useSelector } from 'react-redux';
import logo from "../assets/Logos/Logotipo escola municipal minimalista azul e laranja.png"
import imgProfile from "../assets/profile/user.png";
import apiAxios from '../api/apiAxios';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const Header = () => {
    const [user, setUser] = useState();
    const [isRightHeaderVisible, setIsRightHeaderVisible] = useState(window.innerWidth >= 700 ? true : false);
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
    const profileMenuRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await apiAxios.get(`/api/user/user/${tokenPayload._id}`);
                setUser(response.data.user);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };
        if (tokenPayload && tokenPayload._id) {
            fetchUser();
        }
    }, [tokenPayload]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
                setIsProfileMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleRightHeaderVisibility = () => {
        setIsRightHeaderVisible(!isRightHeaderVisible);
    };

    const toggleProfileMenu = () => {
        setIsProfileMenuOpen(!isProfileMenuOpen);
    };

    const handleLogout = () => {
        dispatch(logout());
        clearUserInfo();
    };

    return (
        <header className={styles.header}>
            <div className={styles.rightSide}>
                <div className={styles.logo}>
                    <img src={logo} alt='logo' />
                </div>
            </div>
            <div className={styles.leftSide}>
                {user && (
                    <div className={styles.profile} onClick={toggleProfileMenu} ref={profileMenuRef}>
                        <img src={user.img ? user.img : imgProfile} alt='profile' />
                        <p>{user.firstName}</p>
                        <p className={styles.arrowProfile}>{isProfileMenuOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</p>
                        {isProfileMenuOpen && (
                            <div className={styles.profileMenu}>
                                <Link to={`/perfil/${tokenPayload._id}`}>Perfil</Link>
                                <Link to={'/'} onClick={handleLogout}>Sair</Link>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </header>
    )
}

export default Header