import React from 'react';
import CardapioComponent from '../../../components/PagesComponents/cardapio/CardapioComponent';

const CardapioAluno = () => {

  return (
    <CardapioComponent/>
  );
}

export default CardapioAluno;
