import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './AgendaViewComponent.module.css';
import { IoIosArrowDown } from "react-icons/io";

const AgendaViewComponent = ({ date, events, onClose }) => {
    const contentRef = useRef(null);
    const [closing, setClosing] = useState(false);

    const handleClose = (e) => {
        if (contentRef.current && !contentRef.current.contains(e.target)) {
            setClosing(true);
        }
    };

    const handleAnimationEnd = () => {
        if (closing) {
            onClose();
        }
    };

    useEffect(() => {
        const contentElement = contentRef.current;
        contentElement.addEventListener('animationend', handleAnimationEnd);
        return () => {
            contentElement.removeEventListener('animationend', handleAnimationEnd);
        };
    }, [closing]);

    const formatDate = (dateStr) => {
        const [datePart, timePart] = dateStr.split(' ');
        const [day, month, year] = datePart.split('/').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);

        const dateObj = new Date(year, month - 1, day, hours, minutes, seconds);

        const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        const formattedMonth = monthNames[dateObj.getMonth()];
        const dayOfMonth = dateObj.getDate();
        return { formattedMonth, day: dayOfMonth };
    };

    return (
        <div className={styles.allScreen} onClick={handleClose}>
            <div ref={contentRef} className={`${styles.content} ${closing ? styles.slideOut : styles.slideIn}`}>
                <div className={styles.header}>
                    <button onClick={() => setClosing(true)} className={styles.closeButton}><IoIosArrowDown /></button>
                </div>
                <div className={styles.eventsList}>
                    {events.length > 0 ? (
                        events.map((event, index) => {
                            const { formattedMonth, day } = formatDate(event.date);
                            return (
                                <React.Fragment key={index}>
                                    <div className={styles.Display}>
                                        <div className={styles.dateDisplay}>
                                            <div className={styles.day}>{day}</div>
                                            <div className={styles.month}>{formattedMonth}</div>
                                        </div>
                                        <h3>Agenda {event.serie}</h3>
                                    </div>
                                    <div className={styles.materias}>
                                        {event.materia.map((mat, matIndex) => (
                                            <div style={{ borderLeft: `solid 3px ${mat.color}` }} key={`${index}-${matIndex}`} className={styles.event}>
                                                <h3>{mat.materia}</h3>
                                                <div className={styles.event1}>
                                                    <div>
                                                        <h4>Descrição:</h4>
                                                        <p>{mat.description}</p>
                                                    </div>
                                                    <div></div>
                                                </div>
                                                <div className={styles.event2}>
                                                    <div>
                                                        <h4>Objetivo do conhecimento:</h4>
                                                        <p>{mat.objetivoConhecimento}</p>
                                                    </div>
                                                    <div>
                                                        <h4>Habilidades:</h4>
                                                        <p>{mat.habilidades}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.event3}>
                                                    <div>
                                                        <h4>Metodologia:</h4>
                                                        <p>{mat.metodologia}</p>
                                                    </div>
                                                    <div>
                                                        <h4>Material:</h4>
                                                        <p>{mat.material}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <p>Não há eventos para esta data.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

AgendaViewComponent.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    events: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string.isRequired, // Adicionado date como string
        materia: PropTypes.arrayOf(PropTypes.shape({
            materia: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            objetivoConhecimento: PropTypes.string,
            habilidades: PropTypes.string,
            metodologia: PropTypes.string,
            material: PropTypes.string
        })).isRequired
    })).isRequired,
    onClose: PropTypes.func.isRequired
};

export default AgendaViewComponent;
