import React from 'react';
import InformesComponent from '../../../components/PagesComponents/InformesComponent';

const InformesAluno = () => {
    return (
        <InformesComponent/>
    )
}

export default InformesAluno;
