import React from 'react';
import { PropagateLoader } from 'react-spinners';
import styles from "./Loading.module.css"

const Loading = () => {
    return (
        <div className={styles.screen}>
            <div>
                <PropagateLoader color="#ffde59" size={15} />
            </div>
        </div>
    );
}

export default Loading;
